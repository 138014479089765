<template>
  <div id="contextMenu" tabindex="0" :style="{ left: eleft + 'px', top: etop + 'px' }">
    <ul>
      <li v-for="(m, k) in data.menu" :key="k" 
        :title="m.disabledTitle ? m.disabledTitle : ''">
        <a
          v-if="m.name != 'sep'"
          :class="{ contextMenuDisabled: m.disabled }"          
          v-on:click="
            $el.blur();
            m.cb(data.val, data.col);
          "
        >
          <div class="icon">
            <i :class="m.icon"></i>
          </div>
          <div v-if="m.submenu" class="info">
            <v-icon>mdi-menu-right</v-icon>
          </div>
          <div class="label">{{ m.name }}</div>
        </a>
        <div v-else class="separator"></div>

        <div v-if="m.submenu" class="submenu">
          <ul>
            <li v-for="(sm, sk) in m.submenu" :key="'sub-' + sk">
              <a
                v-if="sm.name != 'sep'"
                v-on:click="
                  $el.blur();
                  sm.cb(data.val, data.col);
                "
              >
                <div class="icon">
                  <i :class="m.icon"></i>
                </div>
                <div class="label">{{ sm.name }}</div>
              </a>
              <div v-else class="separator"></div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data: function () {
    return {
      ewidth: 0,
      eheight: 0,
    };
  },
  mounted: function () {
    this.$el.focus();
    this.$el.addEventListener("blur", () => {
      this.$emit("update:data", false);
    });

    this.ewidth = this.$el.clientWidth;
    this.eheight = this.$el.offsetHeight;
  },
  computed: {
    eleft: function () {
      if (this.ewidth) {
        return this.data.left > window.innerWidth / 2
          ? this.data.left - (this.ewidth + 15)
          : this.data.left + 15;
      } else {
        return window.innerWidth / 2;
      }
    },
    etop: function () {
      if (this.eheight) {
        return this.data.top > window.innerHeight / 2
          ? this.data.top - this.eheight
          : this.data.top + 15;
      } else {
        return window.innerHeight / 2;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contextMenuDisabled {
  color: rgba(#0f131e, 0.5) !important;
  pointer-events: none;
}

#contextMenu {
  position: absolute;
  left: 0px;
  top: 0px;
  background: white;
  z-index: 2000;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  border: #becde4 1px solid;
  border-radius: 5px;
  outline: 1px transparent solid;

  ul {
    list-style: none !important;
    margin: 0px;
    padding: 0px;

    li {
      text-align: left;
      position: relative;
      a {
        text-align: left;
        color: #0f131e;
        text-decoration: none;
        font-size: 13px;
        padding: 10px;
        display: block;
        user-select: none;
        cursor: pointer;

        .icon {
          float: left;
          width: 15px;
          text-align: center;
          line-height: 20px;
        }

        .label {
          margin-left: 28px;
          margin-right: 80px;
          padding-right: 20px;
          line-height: 20px;
        }

        .info {
          float: right;
          color: #0b1b36;
          font-size: 11px;
          line-height: 20px;
        }
      }

      &:hover {
        /* background: rgba(0, 0, 0, 0.05); */
        background: #f2f9ff;

        .submenu {
          display: block;
        }
      }

      .separator {
        border-bottom: #00a0e0 1px dotted;
        height: 1px;
        width: 100%;
      }

      .submenu {
        display: none;
        position: absolute;
        background: white !important;
        z-index: 2010;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
        border: #becde4 1px solid;
        border-radius: 5px;
        outline: 1px transparent solid;
        left: 290px;
        top: 0px;
        width: 300px;

        ul {
          li {
            a {
              .label {
                margin-left: 28px !important;
                padding-right: 20px !important;
                line-height: 20px !important;
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>